<template>
    <div
    class="md:w-1/3 md:p-2 md:min-w-[350px] card-wrap"
    :data-match-commencing="match.commencing"
    v-click-outside="hideAllOverlays"
    >
        <div
            class="initial-card relative border border-black flex flex-col h-full bg-white"
        >
      <div class="top p-2 flex-1">
        <span class="text-gray-400 text-center w-full block text-sm title">
          {{ title }}
        </span>
        <div class="mt-2 flex items-center">
          <div class="w-1/4 text-center text-xs side-image">
            <div
              class="mx-auto w-10 h-10 mb-4 flex items-center justify-center"
            >
              <img :src="match.home_team.logo" />
            </div>
            {{ match.home_team.name }}
          </div>
          <div class="w-2/4 mx-4">
            <div class="flex w-full space-x-4 justify-center">
              <div class="text-xs flex-1 text-gray-400 text-center">
                home
                <span
                  class="block mt-2 text-base"
                  :class="{
                    ' text-green-400': highestPrediction(
                      match.home_team.prediction
                    ),
                  }"
                  >NA%</span
                >
              </div>
              <div class="text-xs flex-1 text-gray-400 text-center">
                draw
                <span
                  class="block mt-2 text-base"
                  :class="{
                    ' text-green-400': highestPrediction(match.draw.prediction),
                  }"
                  >NA%</span
                >
              </div>
              <div class="text-xs flex-1 relative text-gray-400 text-center">
                away
                <span
                  class="block mt-2 text-base"
                  :class="{
                    ' text-green-400': highestPrediction(
                      match.away_team.prediction
                    ),
                  }"
                  >NA%</span
                >
              </div>
            </div>

            <div class="flex items-center justify-center mt-4">
              <router-link
                  to="/signup"
                class="text-blue-700 uppercase text-xs underline flex items-center justify-center more-info"
              >
                Upgrade to access
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 7l5 5m0 0l-5 5m5-5H6"
                  />
                </svg>
              </router-link>
            </div>
          </div>
          <div class="w-1/4 text-center text-xs side-image">
            <div
              class="mx-auto w-10 h-10 mb-4 flex items-center justify-center"
            >
              <img :src="match.away_team.logo" />
            </div>
            {{ match.away_team.name }}
          </div>
        </div>
      </div>
      <div v-if="this.displayOdds" class="bottom p-2 bg-gray-200 flex">
        <div class="flex-1">
          <div class="flex text-sm">
            <span
              v-on:click="toggleItem('best_odds')"
              class="relative flex-1 text-center group underline"
            >
              <p>best_odds</p>

              <div
                :class="{ '!block': itemShowing['best_odds'] }"
                class="absolute left-1/2 p-2 border text-xs w-48 z-20 bottom-full transform text-black -translate-x-1/2 bg-white hidden w-1/4 ml-7"
              >
                <p class="overflow-hidden">
                  the current best odds available from high street bookmakers
                </p>
              </div>
            </span>

            <div class="flex w-2/3 items-center justify-center flex-shrink-0">
              <span
                v-on:click="toggleItem('home_team')"
                class="relative flex-1 text-center group underline"
                :class="{
                  ' text-green-400': compareOdds(
                    decimalToFraction(match.home_team.best_odds),
                    decimalToFraction(match.home_team.fair_odds)
                  ),
                }"
              >
                {{ convertOdds(match.home_team.best_odds) }}
                <div
                  :class="{ '!block': itemShowing['home_team'] }"
                  class="absolute left-1/2 p-2 border text-xs w-48 z-20 bottom-full transform text-black -translate-x-1/2 bg-white hidden"
                >
                  <span
                    v-for="(key, itemKey) in match.home_team.odds"
                    :key="`${itemKey}+home`"
                  >
                    <span v-if="key.bookie == match.home_team.bookmaker">
                      <span
                        v-if="
                          compareOdds(
                            decimalToFraction(key.odds),
                            decimalToFraction(match.home_team.fair_odds)
                          )
                        "
                        class="text-green-400 font-bold text-sm"
                      >
                        {{ key.bookie }} {{ convertOdds(key.odds) }}
                      </span>
                      <span v-else class="font-bold text-sm">
                        {{ key.bookie }} {{ convertOdds(key.odds) }}
                      </span>
                    </span>
                    <span v-else>
                      <span
                        v-if="
                          compareOdds(
                            decimalToFraction(key.odds),
                            decimalToFraction(match.home_team.fair_odds)
                          )
                        "
                        class="text-green-400"
                      >
                        {{ key.bookie }} {{ convertOdds(key.odds) }}
                      </span>
                      <span v-else>
                        {{ key.bookie }} {{ convertOdds(key.odds) }}
                      </span>
                    </span>
                    <br />
                  </span>
                </div>
              </span>
              <span
                v-on:click="toggleItem('draw')"
                class="relative flex-1 text-center group underline"
                :class="{
                  ' text-green-400': compareOdds(
                    decimalToFraction(match.draw.best_odds),
                    decimalToFraction(match.draw.fair_odds)
                  ),
                }"
              >
                {{ convertOdds(match.draw.best_odds) }}
                <div
                  :class="{ '!block': itemShowing['draw'] }"
                  class="absolute left-1/2 p-2 border text-xs w-48 z-20 bottom-full transform text-black -translate-x-1/2 bg-white hidden"
                >
                  <span
                    v-for="(key, itemKey) in match.draw.odds"
                    :key="`${itemKey}+draw`"
                  >
                    <span v-if="key.bookie == match.draw.bookmaker">
                      <span
                        v-if="
                          compareOdds(
                            decimalToFraction(key.odds),
                            decimalToFraction(match.draw.fair_odds)
                          )
                        "
                        class="text-green-400 font-bold text-sm"
                      >
                        {{ key.bookie }} {{ convertOdds(key.odds) }}
                      </span>
                      <span v-else class="font-bold text-sm">
                        {{ key.bookie }} {{ convertOdds(key.odds) }}
                      </span>
                    </span>
                    <span v-else>
                      <span
                        v-if="
                          compareOdds(
                            decimalToFraction(key.odds),
                            decimalToFraction(match.draw.fair_odds)
                          )
                        "
                        class="text-green-400"
                      >
                        {{ key.bookie }} {{ convertOdds(key.odds) }}
                      </span>
                      <span v-else>
                        {{ key.bookie }} {{ convertOdds(key.odds) }}
                      </span>
                    </span>
                    <br />
                  </span>
                </div>
              </span>
              <span
                v-on:click="toggleItem('away_team')"
                class="relative flex-1 text-center group underline"
                :class="{
                  ' text-green-400': compareOdds(
                    decimalToFraction(match.away_team.best_odds),
                    decimalToFraction(match.away_team.fair_odds)
                  ),
                }"
              >
                {{ convertOdds(match.away_team.best_odds) }}
                <div
                  :class="{ '!block': itemShowing['away_team'] }"
                  class="absolute left-1/2 p-2 border text-xs w-48 z-20 bottom-full transform text-black -translate-x-1/2 bg-white hidden show-on-blur"
                >
                  <span
                    v-for="(key, itemKey) in match.away_team.odds"
                    :key="`${itemKey}+away`"
                  >
                    <span v-if="key.bookie == match.away_team.bookmaker">
                      <span
                        v-if="
                          compareOdds(
                            decimalToFraction(key.odds),
                            decimalToFraction(match.away_team.fair_odds)
                          )
                        "
                        class="text-green-400 font-bold text-sm"
                      >
                        {{ key.bookie }} {{ convertOdds(key.odds) }}
                      </span>
                      <span v-else class="font-bold text-sm">
                        {{ key.bookie }} {{ convertOdds(key.odds) }}
                      </span>
                    </span>
                    <span v-else>
                      <span
                        v-if="
                          compareOdds(
                            decimalToFraction(key.odds),
                            decimalToFraction(match.away_team.fair_odds)
                          )
                        "
                        class="text-green-400"
                      >
                        {{ key.bookie }} {{ convertOdds(key.odds) }}
                      </span>
                      <span v-else>
                        {{ key.bookie }} {{ convertOdds(key.odds) }}
                      </span>
                    </span>
                    <br />
                  </span>
                </div>
              </span>
            </div>
          </div>
          <div class="flex text-sm">
            <span
              v-on:click="toggleItem('fair_odds')"
              class="relative flex-1 text-center group underline"
            >
              <p>fair_odds</p>

              <div
                :class="{ '!block': itemShowing['fair_odds'] }"
                class="absolute left-1/2 p-2 border text-xs w-48 z-20 bottom-full transform text-black -translate-x-1/2 bg-white hidden w-1/4 ml-7"
              >
                <p class="overflow-hidden">
                  the true probability of the result, calculated using machine
                  learning
                </p>
              </div>
            </span>

            <div class="flex w-2/3 items-center justify-center flex-shrink-0">
              <p class="flex-1 text-center">
                {{ convertOdds(match.home_team.fair_odds) }}
              </p>
              <p class="flex-1 text-center">
                {{ convertOdds(match.draw.fair_odds) }}
              </p>
              <p class="flex-1 text-center">
                {{ convertOdds(match.away_team.fair_odds) }}
              </p>
            </div>
          </div>
        </div>

        <div class="w-3/12 text-center relative">
          <div
            class="group flex items-center justify-center h-full"
            v-on:click="toggleItem('tv')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 mx-auto pointer-events-none"
              viewBox="0 0 20 20"
              fill="currentColor"
              :class="{ 'opacity-20': !match.tvstations.length }"
            >
              <path
                fill-rule="evenodd"
                d="M3 5a2 2 0 012-2h10a2 2 0 012 2v8a2 2 0 01-2 2h-2.22l.123.489.804.804A1 1 0 0113 18H7a1 1 0 01-.707-1.707l.804-.804L7.22 15H5a2 2 0 01-2-2V5zm5.771 7H5V5h10v7H8.771z"
                clip-rule="evenodd"
              />
            </svg>
            <ul
              v-if="match.tvstations.length"
              class="absolute left-1/2 p-2 list-inside list-disc border text-left text-xs w-48 z-20 bottom-full transform -translate-x-1/2 bg-white hidden"
            >
              <li
                v-for="(station, key) in match.tvstations"
                :key="`${key}+tvstations`"
              >
                {{ station }}
              </li>
            </ul>
            <ul
              v-else
              class="absolute left-1/2 p-2 list-inside list-disc border text-left text-xs w-48 z-20 bottom-full transform -translate-x-1/2 bg-white hidden"
            >
              <li>Not televised.</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="absolute left-[47%] bottom-3 flex items-center justify-center text-center max-w-xs">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#414141"><path d="M240-80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Zm0-80h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM240-160v-400 400Z"/></svg>
      </div>
      <div
        class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
      ></div>
    </div>
    </div>
</template>

<script>
import Vue from "vue";
import ClickOutside from "vue-click-outside";

import API from "./../api";
import _ from "lodash";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement
);

export default {
  name: "FreemiumCard",
  props: [
    "mode",
    "match",
    "showOdds",
    "index",
    "gameCount",
    "competition",
    "botd",
    "oddsType",
    "extraBlurContent",
  ],
  components: {
  },
  data: function () {
    return {
      sliceList: {
        head_to_head: 5,
        home_team: 5,
        away_team: 5,
        home_team_cup: 5,
        away_team_cup: 5,
      },
      showHowCalcuationModal: false,
      allowedCompetitions: [
        "Premier League",
        "La Liga",
        "Bundesliga",
        "Serie A",
        "Ligue 1",
        "Eredivisie",
        "Primeira Liga",
        "Championship",
        "Brasileirão",
        "Major League Soccer",
        "Champions League",
        "Europa League",
        "Europe Conference League",
        "FA Cup",
        "Carabao Cup",
      ],
      gotOverlayData: false,
      gotHomeProfile: false,
      gotAwayProfile: false,
      itemShowing: {
        tv: false,
        info: false,
        away_team: false,
        draw: false,
        home_team: false,
      },
      popoverShowing: false,
      popOverData: {},
      profileData: {},
      toggleTimeFrameDropdowns: {"teamstats": false, "goal_scorers": false, "cards_fouls": false},
      timeFrameDropdowns: {"teamstats": "season", "goal_scorers": "season", "cards_fouls": "season"},
      goalScorersHomeShort: true,
      goalScorersAwayShort: true,
      cardsHomeShort: true,
      cardsAwayShort: true,
      teamNewsShort: true,
      displayOdds: true,
    };
  },
  // do not forget this section
  directives: {
    ClickOutside,
  },
  mounted() {
    if (!this.match) {
      return;
    }
    if (
      (this.showOdds && this.showOddds == "false") ||
      this.showOdds == false
    ) {
      this.displayOdds = false;
    }
    if (this.extraBlurContent) {
      this.getOverlayContent(this.match);
    }
  },
  computed: {
    isShowOddsAllowed() {
      return false;
    },
    isBetPromoAllowed() {
      return this.$store.getters.isBetPromoAllowed;
    },
    chartOptions() {
      return {
        elements: {
          line: {
            borderWidth: 3,
          },
        },
      };
    },
    chartData() {
      const homeData = this.profileData.home
        ? [
            this.profileData.home.percentiles.attack,
            this.profileData.home.percentiles.possession,
            this.profileData.home.percentiles.long_ball,
            this.profileData.home.percentiles.finishing,
            this.profileData.home.percentiles.set_peices_for,
            this.profileData.home.percentiles.set_peices_against,
            this.profileData.home.percentiles.defence,
            this.profileData.home.percentiles.pressing,
          ]
        : [];
      const awayData = this.profileData.away
        ? [
            this.profileData.away.percentiles.attack,
            this.profileData.away.percentiles.possession,
            this.profileData.away.percentiles.long_ball,
            this.profileData.away.percentiles.finishing,
            this.profileData.away.percentiles.set_peices_for,
            this.profileData.away.percentiles.set_peices_against,
            this.profileData.away.percentiles.defence,
            this.profileData.away.percentiles.pressing,
          ]
        : [];

      const datasets = [];
      if (this.profileData.home) {
        datasets.push({
          label: this.profileData.home.team_name,
          data: homeData,
          backgroundColor: "rgba(229, 156, 152, 0.7)",
          borderColor: "rgba(229, 156, 152, 1)",
          pointBackgroundColor: "rgba(229, 156, 152, 0.7)",
        });
      }
      if (this.profileData.away) {
        datasets.push({
          label: this.profileData.away.team_name,
          data: awayData,
          backgroundColor: "rgba(189, 189, 251, 0.7)",
          borderColor: "rgba(189, 189, 251, 1)",
          pointBackgroundColor: "rgba(189, 189, 251, 0.7)",
        });
      }

      return {
        labels: ["ATT", "POSS", "LONG", "FINISH", "SPF", "SPA", "DEF", "PRESS"],
        datasets: datasets,
      };
    },
    title() {
      return this.mode == "chrono" || this.mode == "today"
        ? this.match.competition
        : this.match.time;
    },
    statsTimeframe(){
      return this.timeframe ? this.timeframe : "season";
    },
    groupedByPositionAway() {
      if (this.popOverData.away_team) {
        const groupedPlayers = _.groupBy(
          this.popOverData.away_team.lineup,
          "position"
        );
        return groupedPlayers;
      } else {
        return {};
      }
    },
    groupedByPositionHome() {
      if (this.popOverData.home_team) {
        const groupedPlayers = _.groupBy(
          this.popOverData.home_team.lineup,
          "position"
        );
        return groupedPlayers;
      } else {
        return {};
      }
    },
  },
  methods: {
    sliceItemLength(type, subtype) {
      if (subtype) {
        return this.popOverData[type][subtype].slice(0, this.sliceList[type]);
      } else {
        return this.popOverData[type].slice(0, this.sliceList[type]);
      }
    },
    toggleSliceItem(type) {
      if (this.sliceList[type] == 15) {
        this.sliceList[type] = 5;
      } else {
        this.sliceList[type] = this.sliceList[type] + 5;
      }
    },
    generateBackgroundBar(payload, compareTo) {
      return {
        width: payload.value ? payload.value + "%" : 0,
        backgroundColor:
          payload.value && compareTo.value && payload.value == compareTo.value
            ? "gray"
            : payload.colour == "green"
            ? "#83B76D"
            : "#D56A48",
      };
    },
    toggleTimeFrameDropdown(button) {
      this.toggleTimeFrameDropdowns[button] = !this.toggleTimeFrameDropdowns[button];
      return !this.toggleTimeFrameDropdowns[button];
    },
    getTimeFrameDropdown(type) {
      return this.timeFrameDropdowns[type];
    },
    setTimeFrameDropdown(type, timeframe) {
      this.timeFrameDropdowns[type] = timeframe;
      this.toggleTimeFrameDropdowns[type] = !this.toggleTimeFrameDropdowns[type];
      return this.timeFrameDropdowns[type];
    },
    getLastName(payload) {
      const index = payload.indexOf(" ");
      const result = payload.substring(index + 1);
      return result;
    },
    toggleOnly5(payload) {
      this[payload] = !this[payload];
    },
    colorOfMatch(payload, key) {
      if (payload[key] == "L") {
        return "bg-red-100";
      }
      if (payload[key] == "D") {
        return "bg-gray-100";
      }
      if (payload[key] == "W") {
        return "bg-green-100";
      }
    },
    recentForm(payload) {
      try {
        return this.popOverData[payload].recent_form.split("");
      } catch (err) {
        return [];
      }
    },
    recentHomeForm(payload) {
      try {
        return this.popOverData[payload].stats["season"].home_form.split("");
      } catch (err) {
        return [];
      }
    },
    recentAwayForm(payload) {
      try {
        return this.popOverData[payload].stats["season"].away_form.split("");
      } catch (err) {
        return [];
      }
    },
    recentCupForm(payload) {
      try {
        return this.popOverData[payload].recent_cup_form.split("");
      } catch (err) {
        return [];
      }
    },
    aiInsights(payload){
      try {
        return this.popOverData[payload].insights;
      } catch (err) {
        return [];
      }
    },
    addNth(n) {
      let ordinal = require("ordinal");
      return ordinal(+n);
    },
    async getOverlayContent() {
      let url = `/match`;
      if (this.botd) {
        url += `?matchKey=${this.match.match_key}&dateKey=${this.match.key}`;
      } else {
        url += `?matchKey=${this.match.key}`;
      }
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      if (urlParams.get("mode") === "test") {
        url = "/test";
      }
      const response = await API.get(url);
      let responseData = response.data;
      this.popOverData = responseData;

      if (this.allowedCompetitions.includes(this.popOverData.competition)) {
        this.getProfileContent();
      }

      this.gotOverlayData = true;
    },
    async getProfileContent() {
      console.log("Trying to get profile content");
      try {
        // const homeResponse = await API.get(
        //   `/team/profile?teamId=${this.popOverData.home_team.team_id}`
        // );
        // if (homeResponse.data.percentiles) {
        //   Vue.set(this.profileData, "home", homeResponse.data);
        // }
        this.gotHomeProfile = true;
      } catch (error) {
        this.gotHomeProfile = true;
        // console.log(error);
      }
      try {
        // const awayResponse = await API.get(
        //   `/team/profile?teamId=${this.popOverData.away_team.team_id}`
        // );
        // if (awayResponse.data.percentiles) {
        //   Vue.set(this.profileData, "away", awayResponse.data);
        // }
        this.gotAwayProfile = true;
      } catch (error) {
        this.gotAwayProfile = true;
        // console.log(error);
      }
    },
    freemiumPopup() {
        // eslint-disable-next-line no-undef
        gtag("event", "freemium_bait", {
        event_category: "engagement",
        event_label: "freemium_bait",
      });
      this.popoverShowing = !this.popoverShowing;
      if(!this.popoverShowing) {
        document.getElementById("blur-wrapper").classList.remove("blur-sm");
      } else {
        document.getElementById("blur-wrapper").classList.add("blur-sm");
      }
    },
    moreInfoToggle() {
      // eslint-disable-next-line no-undef
      gtag("event", "more_info", {
        event_category: "engagement",
        event_label: "more_info",
      });
      this.popoverShowing = !this.popoverShowing;
      if (!this.popoverShowing) {
        document.getElementById("blur-wrapper").classList.remove("blur-sm");
        this.gotOverlayData = false;
      } else {
        document.getElementById("blur-wrapper").classList.add("blur-sm");
        this.getOverlayContent(this.match);
      }
    },
    triggerTrack(event) {
      const elementId = event.target.id;
      if (elementId) {
        // eslint-disable-next-line no-undef
        gtag("event", elementId, {
          event_category: "engagement",
          event_label: elementId,
      });
      }
    },
    hideAllOverlays() {
      this.itemShowing = {
        tv: false,
        info: this.itemShowing.info ? true : false,
        away_team: false,
        draw: false,
        home_team: false,
      };
    },
    toggleItem(payload) {
      if (payload == "tv_button") {
        // eslint-disable-next-line no-undef
        gtag("event", "tv", {
          event_category: "engagement",
          event_label: "tv_button",
        });
      } else if (["home_team", "away_team", "draw"].includes(payload)) {
        // eslint-disable-next-line no-undef
        gtag("event", "show_best_odds", {
          event_category: "engagement",
          event_label: "show_best_odds",
        });
      }
      let resetItems = {
        tv: false,
        info: false,
        away_team: false,
        draw: false,
        home_team: false,
        best_odds: false,
        fair_odds: false,
      };
      Vue.set(resetItems, payload, !this.itemShowing[payload]);
      this.itemShowing = resetItems;
    },
    niceName(key) {
      let returnResponse = false;
      if (key == "btts") {
        returnResponse = "BTTS";
      }
      if (key == "FT_over_2_5") {
        returnResponse = "Match Over 2.5";
      }
      if (key == "FT_over_3_5") {
        returnResponse = "Match Over 3.5";
      }
      if (key == "HT_clean_sheet") {
        returnResponse = "Home Clean Sheet";
      }
      if (key == "HT_over_0_5") {
        returnResponse = "Home Over 0.5";
      }
      if (key == "HT_over_1_5") {
        returnResponse = "Home Over 1.5";
      }
      if (key == "AT_clean_sheet") {
        returnResponse = "Away Clean Sheet";
      }
      if (key == "AT_over_0_5") {
        returnResponse = "Away Over 0.5";
      }
      if (key == "AT_over_1_5") {
        returnResponse = "Away Over 1.5";
      }
      return returnResponse;
    },
    toScoreFirstHalf(match, info, team) {
      var firstHalfGoals;
      if (team == "home_team") {
        firstHalfGoals =
          info.home_team.stats.goals.F.first_half /
          info.home_team.stats.goals.F.total;
        return Math.round(match.predictions.HT_over_0_5 * firstHalfGoals);
      } else if (team == "away_team") {
        firstHalfGoals =
          info.away_team.stats.goals.F.first_half /
          info.away_team.stats.goals.F.total;
        return Math.round(match.predictions.AT_over_0_5 * firstHalfGoals);
      } else {
        firstHalfGoals =
          (info.home_team.stats.goals.F.first_half /
            info.home_team.stats.goals.F.total) *
          (info.away_team.stats.goals.F.first_half /
            info.away_team.stats.goals.F.total);
        return Math.round(
          (info.home_team.stats.goals.F.first_half /
            info.home_team.stats.goals.F.total +
            info.away_team.stats.goals.F.first_half /
              info.away_team.stats.goals.F.total -
            firstHalfGoals) *
            100
        );
      }
    },
    setStatsTimeframe(timeframe) {
      this.timeframe = timeframe;
    },  
    highestPrediction(payload) {
      return (
        payload ==
        Math.max(
          this.match.home_team.prediction,
          this.match.draw.prediction,
          this.match.away_team.prediction
        )
      );
    },
    highestBestOdd(payload) {
      return (
        payload ==
        Math.max(
          this.match.home_team.best_odds,
          this.match.draw.best_odds,
          this.match.away_team.best_odds
        )
      );
    },

    gcd(a, b) {
      if (b < 0.01) return a;
      return this.gcd(b, Math.floor(a % b));
    },
    convertOdds(decimal) {
      if (decimal == null){
        return "N/A";
      }
      if (this.oddsType == "decimal") {
        // return parseFloat(parseFloat(decimal) - parseFloat(1)).toFixed(2);
        return parseFloat(parseFloat(decimal)).toFixed(2);
      } else {
        var fraction = Math.round((decimal - 1) * 10) / 10;
        var len = fraction.toString().length - 1;
        var denominator = Math.pow(10, len);
        var numerator = Math.round((fraction * denominator * 10) / 10);
        var divisor = this.gcd(numerator, denominator);
        numerator /= divisor;
        denominator /= divisor;
        if (Math.floor(numerator) == -1 || Math.floor(denominator) == -1) {
          return "N/A";
        } else {
          return Math.floor(numerator) + "/" + Math.floor(denominator);
        }
      }
    },
    decimalToFraction(decimal) {
      if (decimal== null){
        return "N/A";
      }
      if (this.oddsType == "decimal") {
        return decimal;
      } else {
        var fraction = Math.round((decimal - 1) * 10) / 10;
        var len = fraction.toString().length - 1;
        var denominator = Math.pow(10, len);
        var numerator = Math.round((fraction * denominator * 10) / 10);
        var divisor = this.gcd(numerator, denominator);
        numerator /= divisor;
        denominator /= divisor;
        return [Math.floor(numerator), Math.floor(denominator)];
      }
    },
    compareOdds(odds, fairOdds) {
      if (this.oddsType == "decimal") {
        return odds * 100 > fairOdds * 100; //remove decimal places as this complicates things
      } else {
        // Declare nume1 and nume2 for get the value
        // of first numerator and second numerator
        var a = odds[0];
        var b = odds[1];
        var c = fairOdds[0];
        var d = fairOdds[1];

        // Compute ad-bc
        var Y = a * d - b * c;

        return Y > 0 ? true : false;
      }
    },
    getStage(match, data) {
      if (match.stage == "Regular Season") {
        if ("round" in data) {
          return "Gameweek " + match.round;
        } else {
          return (
            "Gameweek " +
            (Number(data.home_team.stats.matches_played) + Number(1))
          );
        }
      } else {
        return match.stage;
      }
    },
  },
};
</script>