<template>
  <div class="relative">
    <div class="relative">
      <div class="absolute inset-0 bg-white bg-center"></div>
      <div
        class="absolute inset-0 bg-pattern bg-white bg-center opacity-40"
      ></div>
      <div
        class="h-1/2 left-0 right-0 absolute bottom-0 bg-gradient-to-t to-transparent from-white"
      ></div>
      <section
        class="max-w-6xl mx-auto px-4 pt-12 md:pt-16 relative md:min-h-[600px]"
      >
        <div class="justify-center">
            <img class="mx-auto" style="max-width: 100%;" src="@/assets/fdmesc.gif" />
            <h2 class="text-xl mx-0.5	font-normal text-center pb-4 md:mx-60 ">
              Make better bets in seconds using our  
              <span class="font-extrabold">MatchView™</span> cards & AI powered predictions.
            </h2>
            <div v-if="countdown" class="flex-1 mx-auto p-2 border rounded-2xl shadow-xl bg-white h-10 pb-4" style="width: fit-content;">
              <span class="m=1" v-if="nextMatchTime && countdown != 'Now!' " >Next Match in 🗓️ {{ countdown }}</span>
              <span class="m=1" v-else-if="nextMatchTime && countdown == 'Now!' ">Next Match has started! 🚀</span>
            </div>
          <div class="flex-1 md:pt-4">
            <!-- <div class="md:mt-0 mt-10"></div> v-if="nextMatchTime"> -->
            <div class="md:mt-0 mt-10">
              <Card
                mode="chrono"
                :match="nextMatch"
                :showOdds="true"
                v-if="nextMatch?.date"
                class="md:w-1/3 mx-auto w-full mb-10"
              />
            </div>
            <div
              class="flex flex-col inset-0 items-center justify-center relative pb-8"
            >
              <a href="https://numbersgame.uk/signup/monthly" class="block space-x-4">
                <router-link
                  :to="`/signup/${type}`"
                  class="shadow-xl inline-flex bg-blue-700 items-center justify-center border border-blue-700 text-xs p-3 px-6 font-bold uppercase lg:text-lg text-white"
                >
                  Get Started
                </router-link>
              </a>
              <div class="block mt-4">
                <button
                  class="text-xs uppercase text-brandGrey underline"
                >
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <template v-if="nextMatch?.date">
      <div class="bg-white">
        <img
          src="@/assets/angled/1.svg"
          class="absolute w-full md:-translate-y-16"
        />
        <section class="max-w-6xl mx-auto px-4 py-4 md:py-12 relative">
          <div class="p-5 md:p-10 border rounded-2xl shadow-xl bg-white">
            <div
              class="md:flex items-center justify-center gap-10 md:gap-8"
            >
              <div class="flex-1 text-left">
                <h2
                  class="uppercase text-blue-700 mb-2 font-bold text-2xl drop-shadow-lg"
                >
                  Whats the Point? 🤔
                </h2>
                <p>
                  Most punters are betting on a whim because they don't have access to the data they need.
                </p><br>
                <p>
                  Those that <span class="italic font-bold">do</span> use data are taking hours to do their research.
                </p><br> 
                <p>
                  <strong>Enter Numbers Game.</strong>
                </p><br><br>
                <span class="hidden md:inline">
                  <h2 class="uppercase text-blue-700 mb-2 font-bold text-2xl drop-shadow-lg mb-5 text-center md:text-left">
                      What We Offer You 🚀
                  </h2>
                  <span class="flex mb-5">
                    <img class="m-auto mr-1.5" style="width: 3rem; height: 3rem;" src="@/assets/Greentick.png" />
                    <div class="border rounded-2xl shadow-xl bg-white" style="height: fit-content; width: fit-content;">
                      <div class="flex-1 text-left">
                        <p class="p-2">
                          Everything you need to make data-backed football betting quicker, easier and more fun.
                        </p>                       
                      </div>
                    </div>
                  </span>
                  <span class="flex mb-5">
                    <img class="m-auto mr-1.5" style="width: 3rem; height: 3rem;" src="@/assets/Greentick.png" />
                    <div class="border rounded-2xl shadow-xl bg-white" style="height: fit-content; width: fit-content;">
                      <div class="flex-1 text-left">
                        <p class="p-2">
                          Accurate AI powered football predictions for glanceable insights.
                        </p>                       
                      </div>
                    </div>
                  </span>
                  <span class="flex mb-5">
                    <img class="m-auto mr-1.5" style="width: 3rem; height: 3rem;" src="@/assets/Greentick.png" />
                    <div class="border rounded-2xl shadow-xl bg-white" style="height: fit-content; width: fit-content;">
                      <div class="flex-1 text-left">
                        <p class="p-2">
                          Over 50 leagues covered so you're clued up no matter the competition.
                        </p>                       
                      </div>
                    </div>
                  </span>
                  <span class="flex mb-5">
                    <img class="m-auto mr-1.5" style="width: 3rem; height: 3rem;" src="@/assets/Greentick.png" />
                    <div class="border rounded-2xl shadow-xl bg-white" style="height: fit-content; width: fit-content;">
                      <div class="flex-1 text-left">
                        <p class="p-2">
                          A vibrant discord community with exclusive daily tips from the team behind Numbers Game.
                        </p>                       
                      </div>
                    </div>
                  </span>
                </span>
              </div>
              <div class="blur-type-3" v-in-viewport="{ margin: '-50% 0px' }">
                <Card
                  mode="chrono"
                  :match="nextMatch"
                  :showOdds="true"
                  v-if="nextMatch?.date"
                  class="!w-full md:min-w-[385px] relative"
                  :extraBlurContent="true"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="bg-white md:hidden">
        <section class="max-w-6xl mx-auto px-4 py-4 md:py-12 relative">
          <div class="p-5 md:p-10 text-center">
            <h2 class="uppercase text-blue-700 mb-2 font-bold text-2xl drop-shadow-lg mb-5">
              What We Offer You 🚀
            </h2>
            <span class="flex mb-5">
              <img class="m-auto mr-1.5" style="width: 3rem; height: 3rem;" src="@/assets/Greentick.png" />
              <div class="border rounded-2xl shadow-xl bg-white" style="height: fit-content; width: fit-content;">
                <div class="flex-1 md:text-left">
                  <p class="p-2">
                    Everything you need to make data-backed football betting quicker, easier and more fun.
                  </p>                       
                </div>
              </div>
            </span>
            <span class="flex mb-5">
              <img class="m-auto mr-1.5" style="width: 3rem; height: 3rem;" src="@/assets/Greentick.png" />
              <div class="border rounded-2xl shadow-xl bg-white" style="height: fit-content; width: fit-content;">
                <div class="flex-1 md:text-left">
                  <p class="p-2">
                    Accurate AI powered football predictions for glanceable insights.
                  </p>                       
                </div>
              </div>
            </span>
            <span class="flex mb-5">
              <img class="m-auto mr-1.5" style="width: 3rem; height: 3rem;" src="@/assets/Greentick.png" />
              <div class="border rounded-2xl shadow-xl bg-white" style="height: fit-content; width: fit-content;">
                <div class="flex-1 md:text-left">
                  <p class="p-2">
                    Over 50 leagues covered so you're clued up no matter the competition.
                  </p>                       
                </div>
              </div>
            </span>
            <span class="flex mb-5">
              <img class="m-auto mr-1.5" style="width: 3rem; height: 3rem;" src="@/assets/Greentick.png" />
              <div class="border rounded-2xl shadow-xl bg-white" style="height: fit-content; width: fit-content;">
                <div class="flex-1 md:text-left">
                  <p class="p-2">
                    A vibrant discord community with exclusive daily tips from the team behind Numbers Game.
                  </p>                       
                </div>
              </div>
            </span>
          </div>
        </section>
      </div>
      <div class="bg-white">
        <div class="">
          <section class="max-w-6xl mx-auto px-4 py-4 md:py-12 relative">
            <div class="p-5 md:p-10 border rounded-2xl shadow-xl bg-white">
              <div class="mb-4 md:mb-0 text-center md:text-right m-4">
                <h2
                  class="uppercase text-blue-700 mb-4 font-bold text-2xl drop-shadow-lg"
                >
                  Love <span style="color:black">Numbers</span><span style="color:grey">_Game</span> Or Get Your Money Back 💸
                </h2>
                <p>
                  Our community <span class="italic font-bold">loves</span> Numbers Game, and you will too! 
                  <br><br>
                  But, if for any reason you're not satisfied, we offer a full refund within your first week.
                  <br><br>
                  No questions asked. Just email <span class="text-blue-700">support@numbersgame.uk</span>.<br><br>
                </p>
              </div>
              <span class="flex-col md:flex-row gap-1 flex">
                <img class="m-auto md:w-1/3 shadow-xl border rounded-2xl" src="@/assets/Review1.png" />
                <img class="m-auto md:w-1/3 shadow-xl border rounded-2xl" src="@/assets/Review2.png" />
                <img class="m-auto md:w-1/3 shadow-xl border rounded-2xl" src="@/assets/Review3.png" />
              </span>
            </div>
          </section>
        </div>
      </div>
      <div class="bg-white">
        <section class="max-w-6xl mx-auto px-4 py-4 md:py-12 relative">
          <div class="p-5 md:p-10 border rounded-2xl shadow-xl bg-white">
            <div
              class="md:flex flex-row-reverse items-center justify-center gap-10 md:gap-8"
            >
              <div class="flex-1 mb-8 md:mb-0 text-center md:text-left">
                <h2
                  class="uppercase text-blue-700 mb-2 font-bold text-2xl drop-shadow-lg"
                >
                  How It Started 👀
                </h2>
                <p class="md:pr-[20%]">
                  This industry is rife with thieving bookies, false promises and dodgy tipsters charging £50 a month 😬<br><br>
                  We are Olly & Sam, two normal blokes disrupting the football betting industry by making data more accessible 👋🏻<br><br>
                  We built Numbers Game for ourselves, but once we found it made our football betting more fun <span class="italic font-bold">and</span> more successful, we decided we had to share it with our mates 🤝<br><br>
                </p>
                <span class="flex-col md:flex-row gap-1 flex">
                  <img class="m-auto md:w-1/3 shadow-xl border rounded-2xl" src="@/assets/whoarewe1.png" />
                  <img class="m-auto md:w-1/3 shadow-xl border rounded-2xl" src="@/assets/whoarewe2.png" />
                  <img class="m-auto md:w-1/3 shadow-xl border rounded-2xl" src="@/assets/whoarewe3.png" />
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="bg-white">
        <section class="max-w-6xl mx-auto px-4 relative">
          <div class="p-5 md:p-10">
            <div
              class="md:flex flex-row-reverse items-center justify-center gap-10 md:gap-8"
            >
              <div class="flex-1 md:mb-0 text-center">
                <h2
                  class="uppercase text-blue-700 mb-2 font-bold text-3xl drop-shadow-lg sm:pt-4"
                >
                  How It's Going 🔥
                </h2>
                <vee-observe
                  :root="null"
                  :root-margin="'0px'"
                  :threshold="1"
                  :once="true"
                  @on-change="onChange"
                >
                  <div class="flex-1 text-center mb-8 md:mb-0">
                    <h2
                      class="uppercase text-gray-800 font-bold text-[100px] drop-shadow-lg"
                    >
                      {{ animatedNumber }}
                    </h2>
                    <p class="text-lg">
                      FOOTBALL MATCHES CORRECTLY<br />
                      PREDICTED AND COUNTING 🚀
                    </p>
                  </div>
                </vee-observe>
                <div class="block space-x-4 mt-10">
                  <div
                    class="flex flex-col inset-0 items-center justify-center relative pb-8"
                  >
                    <a href="https://numbersgame.uk/signup/monthly" class="block space-x-4">
                      <router-link
                        :to="`/signup/${type}`"
                        class="shadow-xl inline-flex bg-blue-700 items-center justify-center border border-blue-700 text-xs p-3 px-6 font-bold uppercase lg:text-lg text-white"
                      >
                        Get Started
                      </router-link>
                    </a>
                    <div class="block mt-4">
                      <button
                        class="text-xs uppercase text-brandGrey underline"
                      >
                      </button>
                    </div>
                  </div>
              </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="bg-white">
        <img
          src="@/assets/angled/1.svg"
          class="absolute w-full md:-translate-y-24"
        />
        <section class="max-w-6xl mx-auto px-4 py-4 md:py-12 relative">
          <h2
              class="uppercase text-blue-700 mb-2 font-bold text-3xl drop-shadow-lg text-center"
            >
              Discover MatchView™ 🤩
            </h2>
            <p class="text-center mb-6 mt-4">
              <span class="">Our MatchView™ cards enable our mission: <strong>Make Football Data Easy</strong>.<br><br>
              Each upcoming fixture has it's own MatchView™ card that is designed to be simple, glanceable and intuitive, giving you all the data you need in an easy to read format.</span><br><br>
              Find out more below 👇
            </p>
            <div class="md:flex items-center justify-center p-5 md:p-10 gap-10 md:gap-8 border rounded-2xl shadow-xl bg-white">
              <div class="flex-1 mb-8 md:mb-0">
                <h2
                  class="uppercase text-blue-700 mb-2 font-bold text-2xl drop-shadow-lg"
                >
                  Stop Guessing, Start <br />Predicting 🔮
                </h2>
                <p>
                  Our <strong>machine learning algorithms</strong> provide an accuracte percentage chance of each possible result, giving you a clear view of the most likely outcomes.<br>Use this as a quick summary of the underlying data.
                </p>
              </div>
              <div class="blur-type-1" v-in-viewport="{ margin: '-50% 0px' }">
                <Card
                  mode="chrono"
                  :match="nextMatch"
                  :showOdds="true"
                  v-if="nextMatch.date"
                  class="!w-full md:min-w-[385px]"
                />
              </div>
            </div>

        </section>
      </div>
      <div class="bg-white">
        <section class="max-w-6xl mx-auto px-4 py-4 md:py-12 relative">
          <div class="p-5 md:p-10 border rounded-2xl shadow-xl bg-white">
            <div
              class="md:flex flex-row-reverse items-center justify-center gap-10 md:gap-8"
            >
              <div class="flex-1 mb-8 md:mb-0 md:text-right">
                <h2
                  class="uppercase text-blue-700 mb-2 font-bold text-2xl drop-shadow-lg"
                >
                  Predictions & stats, at a glance 👀
                </h2>
                <p>
                  Click <strong><span style="color: #1c4ed8">SEE DATA</span></strong> to view the underlying stats. These dropdowns are designed to allow you to easily find useful data points and profit from them when making bets.
                </p>
                <span class="\">
                  <h2 class="uppercase text-blue-700 mb-2 font-bold text-2xl drop-shadow-lg mb-5 mt-10 text-center text-left md:text-right">
                      Make Data driven decisions in seconds 💪
                  </h2>
                  <span class="mb-5">
                    <div class="flex border rounded-2xl shadow-xl bg-white mb-5" style="height: fit-content; width: fit-content;">
                      <span class="m-auto mx-1.5 text-3xl">🤖</span>
                      <div class="text-left">
                        <p class="p-2">
                          AI Powered Predictions for Both Teams To Score, Most Likely Scoreline, Goalscorers and more.
                        </p>                       
                      </div>
                    </div>
                  </span>
                  <span class="mb-5">
                    <div class="flex border rounded-2xl shadow-xl bg-white mb-5" style="height: fit-content; width: fit-content;">
                      <span class="m-auto mx-1.5 text-3xl">📖</span>
                      <div class="text-left">
                        <p class="p-2">
                          Head to Head and recent form data with historical match stats.
                        </p>                       
                      </div>
                    </div>
                  </span>
                  <span class="mb-5">
                    <div class="flex border rounded-2xl shadow-xl bg-white mb-5" style="height: fit-content; width: fit-content;">
                      <span class="m-auto mx-1.5 text-3xl">📈</span>
                      <div class="text-left">
                        <p class="p-2">
                          Detailed player and team stats with colour coded bars for easy comparison.
                        </p>                       
                      </div>
                    </div>
                  </span>
                  <span class="mb-5">
                    <div class="flex border rounded-2xl shadow-xl bg-white mb-5" style="height: fit-content; width: fit-content;">
                      <span class="m-auto mx-1.5 text-3xl">🌏</span>
                      <div class="text-left">
                        <p class="p-2">
                          MatchView™ cards are generated two weeks in advance for over 50 competitions worldwide.
                        </p>                       
                      </div>
                    </div>
                  </span>
                </span>
              </div>
              <div class="blur-type-3" v-in-viewport="{ margin: '-50% 0px' }">
                <Card
                  mode="chrono"
                  :match="nextMatch"
                  :showOdds="true"
                  v-if="nextMatch.date"
                  class="!w-full md:min-w-[385px] relative"
                  :extraBlurContent="true"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="bg-white">
        <div class="">
          <section class="max-w-6xl mx-auto px-4 py-4 md:py-12 relative">
            <div class="md:flex items-center justify-center gap-10 md:gap-8 p-5 md:p-10 border rounded-2xl shadow-xl bg-white">
              <div class="flex-1 mb-8 md:mb-0">
                <h2
                  class="uppercase text-blue-700 mb-2 font-bold text-2xl drop-shadow-lg"
                >
                  Bang For Your Buck 💸
                </h2>
                <p>
                  Compare our "fair odds" of an outcome with those of the highstreet bookmakers - good odds are highlighted
                  <strong><span style="color: #46d39a">green</span></strong>, allowing you to spot
                  <strong>high value</strong> picks with ease.
                </p>
              </div>
              <div class="blur-type-2" v-in-viewport="{ margin: '-50% 0px' }">
                <Card
                  mode="chrono"
                  :match="nextMatch"
                  :showOdds="true"
                  v-if="nextMatch.date"
                  class="!w-full md:min-w-[385px]"
                />
              </div>
            </div>
          </section>
        </div>
        <div class="bg-white">
        <div class="">
          <section class="mx-auto px-4 relative" style="max-width:60rem">
            <img src="@/assets/JoinTheMovement.png" />
            <h2
              class="mt-4 uppercase text-black mb-2 drop-shadow text-lg text-center italic"
            >
              JOIN THE 100K+ PEOPLE MAKING BETTER BETS USING THE FASTEST
              GROWING SPORTS PREDICTION PLATFORM IN THE WORLD
            </h2>
            <div
              class="flex flex-col inset-0 items-center justify-center relative py-2"
            >
              <div class="block space-x-4">
                <router-link
                  :to="`/signup/${type}`"
                  class="shadow-xl inline-flex bg-blue-700 items-center justify-center border border-blue-700 p-3 px-6 font-bold uppercase text-lg text-white"
                >
                  Sign Me Up
                </router-link>
              </div>
            </div>
          </section>
        </div>
      </div>
      </div>
    </template>
    <template v-else>
      <div class="my-16 flex items-center justify-center">
        <Loader></Loader>
      </div>
    </template>
  </div>
</template>
<script>
// import Arrow from "@/assets/icons/Arrow";
import Card from "@/components/Card";
import Loader from "@/components/Loader";
// import LeagueList from "@/components/LeagueList";
// import InstagramPost from "@/components/InstagramPost";
import VeeObserve from "vee-observe";

import dayjs from "dayjs";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);
import gsap from "gsap";

export default {
  name: "Intro",
  props: ["nextMatch", "nextMatchTime", "totalCorrectMatches"],
  components: {
    // Arrow,
    Card,
    Loader,
    // LeagueList,
    VeeObserve,
    // InstagramPost,
  },

  data() {
    return {
      type: "monthly",
      scrolling: false,
      countdown: false,
      countdownInterval: false,
      number: 0,
      tweenedNumber: 0,
    };
  },
  methods: {
    toggleLogin() {
      document.getElementById("loginbutton").click();
    },
    onChange(entry) {
      if (entry.isIntersecting) {
        this.number = this.totalCorrectMatches;
      }
    },
    pad(num) {
      return num > 9 ? num : "0" + num;
    },
    generateCountdown() {
      let remaining =
        dayjs(this.nextMatchTime * 1000).valueOf() - dayjs().valueOf();

      let days = Math.floor(remaining / (1000 * 60 * 60 * 24)),
        hours = Math.floor(remaining / (1000 * 60 * 60)),
        mins = Math.floor(remaining / (1000 * 60)),
        secs = Math.floor(remaining / 1000),
        dd = days,
        hh = hours - days * 24,
        mm = mins - hours * 60,
        ss = secs - mins * 60;

      const returnString = `${this.pad(dd)}:${this.pad(hh)}:${this.pad(
        mm
      )}:${this.pad(ss)}`;
      this.countdown = returnString;
    },
  },
  computed: {
    animatedNumber: function () {
      return this.tweenedNumber.toFixed(0);
    },
  },
  watch: {
    number: function (newValue) {
      gsap.to(this.$data, { duration: 0.5, tweenedNumber: newValue });
    },
    nextMatchTime() {
      if (this.nextMatchTime) {
        this.countdownInterval = setInterval(() => {
          this.generateCountdown();
        }, 1000);
      }
    },
    countdown() {
      if (
        this.countdown == "00:00:00:00" ||
        this.countdown.charAt(0) == "-" ||
        this.countdown.charAt(1) == "-"
      ) {
        clearInterval(this.countdownInterval);
        this.countdown = "Now!";
        return;
      }
    },
  },
  mounted() {
    this.$refs.video ? this.$refs.video.pause() : false;

    // Listen for scroll events
    window.addEventListener(
      "scroll",
      () => {
        // Clear our timeout throughout the scroll
        window.clearTimeout(this.scrolling);
        this.$refs.video ? this.$refs.video.play() : false;

        // Set a timeout to run after scrolling ends
        this.scrolling = setTimeout(() => {
          // Run the callback
          this.$refs.video ? this.$refs.video.pause() : false;
          console.log("Scrolling has stopped.");
        }, 66);
      },
      false
    );
    var text = document.getElementById("typewriter-header");

    // eslint-disable-next-line no-undef
    var typewriter = new Typewriter(text, {
      loop: true,
      delay: 50,
    });

    typewriter
      .typeString("fun")
      .pauseFor(1000)
      .changeDeleteSpeed(10)
      .deleteChars(10)   
      .typeString("fast")
      .pauseFor(1000)
      .changeDeleteSpeed(10)
      .deleteChars(10)
      .typeString("easy")
      .pauseFor(1000)
      .changeDeleteSpeed(10)
      .deleteChars(10)
      .start();
  },
};
</script>
