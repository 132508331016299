<template>
  <div class="border-t-2 border-gray-400" v-if="!noMatches">
  <div class="py-16 relative">
    <!-- <div class="inset-0 absolute bg-pattern bg-center"></div> -->
    <h2
      class="text-brandGrey uppercase text-center text-3xl font-bold -mb-8 relative z-10"
    >
      {{ title }}
    </h2>
    <template>
      <MatchDay
        v-for="(date, key) in groupByChooser"
        :date="key"
        :matches="date"
        :key="key"
        :mode="mode"
        :competition="title"
        :oddsType="oddsType"
        :subType="subType"
        class="relative z-10"
      />
    </template>
  </div>
  </div>
</template>

<script>
import MatchDay from "../components/MatchDay";
var _ = require("lodash");

export default {
  name: "Competition",
  props: ["data", "mode", "oddsType", "subType"],
  components: {
    MatchDay,
  },
  computed: {
    groupByDate() {
      let groupByDate = {};
      _.forEach(this.data.matches, (match) => {
        if (!groupByDate[match.date]) {
          groupByDate[match.date] = [];
        }
        groupByDate[match.date].push(match);
      });
      return groupByDate;
    },
    groupByTime() {
      let groupByTime = {};
      _.forEach(this.data.matches, (match) => {
        if (!groupByTime[match.time]) {
          groupByTime[match.time] = [];
        }
        groupByTime[match.time].push(match);
      });
      return groupByTime;
    },
    groupByChooser() {
      return this.mode == "chrono" || this.mode == "today"
        ? this.groupByTime
        : this.groupByDate;
    },
    title() {
      return this.mode == "chrono" || this.mode == "today"
        ? this.data.date
        : this.data.competition;
    },
    noMatches() {
      return _.isEmpty(this.groupByChooser);
    }
  },
};
</script>
