<template>
  <section class="mx-auto px-4 max-w-6xl match-to-scroll-to">
    <h3 class="text-center block mb-4 mt-10 text-base text-brandGrey">
      {{ date }}
    </h3>

    <div class="space-y-4 md:space-y-0 md:flex flex-wrap justify-center">
      <div v-for="(match, index) in matches" :key="index" class="md:contents">
        <Card
          v-if="match.freemium_match || subType == 'active'"
          :key="index"
          :index="index"
          :match="match"
          :gameCount="gameCount"
          :competition="competition"
          :oddsType="oddsType"
          :mode="mode"
        />
        <FreemiumCard
          v-if="!match.freemium_match && subType == 'inactive'"
          class="blur-freemium"
          :key="index"
          :index="index"
          :match="match"
          :gameCount="gameCount"
          :competition="competition"
          :oddsType="oddsType"
          :mode="mode"
        />
      </div>
    </div>


    <!-- <div class="space-y-4 md:space-y-0 md:flex flex-wrap justify-center" >
      <div v-for="(match, index) in matches" :key="index">
        <span v-if="subType == 'inactive'">
            <Card
              v-if="match.freemium_match"
              :index="index"
              :match="match"
              :gameCount="gameCount"
              :competition="competition"
              :oddsType="oddsType"
              :mode="mode"
              />
              <FreemiumCard
                v-else 
                class="blur-freemium"
                :index="index"
                :match="match"
                :gameCount="gameCount"
                :competition="competition"
                :oddsType="oddsType"
                :mode="mode"
              />
        </span>
        <span v-else>
          <Card
            :index="index"
            :match="match"
            :gameCount="gameCount"
            :competition="competition"
            :oddsType="oddsType"
            :mode="mode"
          />
        </span>
      </div>
    </div> -->
  </section>
</template>

<script>
import Card from "../components/Card";
import FreemiumCard from "./FreemiumCard.vue";

export default {
  name: "MatchDay",
  props: ["date", "matches", "mode", "competition", "oddsType", "index", "subType"],
  components: {
    Card,
    FreemiumCard
  },
  computed: {
    gameCount() {
      return this.matches.length;
    },
  },
};
</script>
